import { useCallback } from 'react';
import { Box, LoadingButton, Stack, Typography } from 'design-system';

import Layout from '@src/shared/Layout/Layout';
import { Contacts } from '@src/shared/contacts/Contacts';
import { HeroHeader } from '@src/shared/hero-header/HeroHeader';

export const TooOldPage: React.FC = () => {
  const handleRetryClick = useCallback(() => {
    void (() => {
      window.location.href = 'https://www.flashpack.com/adventure-trips/';
    })();
  }, []);

  return (
    <Layout
      HeroComponent={
        <HeroHeader text="Oops! This adventure is for a different age-range." />
      }
    >
      <Stack gap={2} maxWidth={610} alignItems={'center'} margin={'0 auto'}>
        <Typography
          whiteSpace={'break-spaces'}
          variant="H5"
          typography={{
            xs: 'H6',
            sm: 'H5',
          }}
        >
          Apologies, this trip is for people aged 30-49.
        </Typography>
        <Typography
          variant="Body M"
          typography={{
            xs: 'Body S',
            sm: 'Body M',
          }}
        >
          But, don’t worry, we have exclusive trips designed for customers aged 45-59.
        </Typography>
        <LoadingButton variant={'contained'} onClick={handleRetryClick}>
          See 45-59 trips
        </LoadingButton>

        <Box mt={7}>
          <Contacts />
        </Box>
      </Stack>
    </Layout>
  );
};
