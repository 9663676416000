import { CheckoutExpandableSection } from '@src/shared/checkout-sections-expandable/CheckoutExpandableSections';
import { useRouting } from '@src/shared/hooks';
import { CheckoutSectionName } from '@src/v3/checkout-sections/CheckoutSections';
import { Button, Typography } from 'design-system';

export const CreateAccountSection = ({
  activeSection,
}: {
  activeSection: CheckoutSectionName;
}) => {
  const { updateQueryParams } = useRouting<{ section: CheckoutSectionName }>();

  return (
    <CheckoutExpandableSection
      title="Hold your spot"
      open={activeSection === CheckoutSectionName.CreateAccountSection}
      topDivider={false}
      onEdit={() => {
        updateQueryParams(
          {
            preserveExisting: true,
            toAdd: { section: CheckoutSectionName.CreateAccountSection },
          },
          { replace: true },
        );
      }}
    >
      <Typography variant="Body S">
        Create an account to hold your spot. You can change your email address later.
      </Typography>
      <Button
        variant="contained"
        onClick={() => {
          updateQueryParams(
            {
              preserveExisting: true,
              toAdd: { section: CheckoutSectionName.PersonaliseBookingSection },
            },
            { replace: true },
          );
        }}
      >
        Create account
      </Button>
    </CheckoutExpandableSection>
  );
};
