import { useOrderDetails, useRouting } from '@src/shared/hooks';
import Layout from '@src/shared/Layout/Layout';
import { OrderDetails } from '@src/shared/order-details/OrderDetails';
import { PageFormLoader } from '@src/shared/page-form-loader/PageFormLoader';
import { ResponsiveSideBySide } from '@src/shared/responsive-side-by-side/ResponsiveSideBySide';
import {
  CheckoutSectionName,
  CheckoutSections,
} from '@src/v3/checkout-sections/CheckoutSections';
import { Stack } from 'design-system';
import { useCustomerAuthorization } from '@src/auth/AuthorizationProvider';
import { useEffect } from 'react';

export const BookPageV3 = () => {
  const { queryParams, updateQueryParams } = useRouting<{
    departureCode: string;
    currencyCode: string;
    section: CheckoutSectionName;
  }>();

  const { currencyCode: _ } = queryParams;
  const { data: orderDetailsData, loading: orderDetailsLoading } = useOrderDetails();
  const { currentUser, loadingAuthorization } = useCustomerAuthorization();
  // const { loading: loadingBasket, basket } = useBasket();

  useEffect(() => {
    if (loadingAuthorization) {
      return;
    }

    if (!currentUser) {
      updateQueryParams(
        {
          toAdd: { section: CheckoutSectionName.CreateAccountSection },
          preserveExisting: true,
        },
        {
          replace: true,
        },
      );
    } else {
      updateQueryParams(
        {
          toAdd: { section: CheckoutSectionName.PersonaliseBookingSection },
          preserveExisting: true,
        },
        {
          replace: true,
        },
      );
    }
  }, [currentUser, loadingAuthorization]);

  if (orderDetailsLoading || loadingAuthorization) {
    return (
      <Layout>
        <PageFormLoader />
      </Layout>
    );
  }

  return (
    <Layout>
      <ResponsiveSideBySide
        mainSection={<CheckoutSections />}
        additionalSection={
          <Stack gap={3}>
            <OrderDetails
              loading={orderDetailsLoading}
              orderDetails={orderDetailsData?.orderDetails}
            />
          </Stack>
        }
      />
    </Layout>
  );
};
