import { Basket, PaymentOptionType } from '@flashpack/graphql';
import {
  getDefaultPaymentOptionType,
  isOnlyFullBalanceOptionAvailable,
} from '@src/book-departure/payment-options/paymentOptionsUtil';
import {
  RadioAccordionGroup,
  Stack,
  Typography,
  Divider,
  RadioAccordionGroupOption,
} from 'design-system';
import { FC, useCallback, useMemo, useState } from 'react';
import { FormApi } from 'final-form';
import { BookDepartureFormValues } from '../BookDeparturePage';
import { createBuyNowPayLaterRadioGroupOption } from './BuyNowPayLaterOption';
import { createPayFullAmountRadioGroupOption } from './PayFullAmountOption';
import { createPaymentPlanOptionContent } from './PaymentPlanOption';
import { getPaymentPlanLatestStartDate } from '@src/shared/instalments-calculator/InstalmentsCalculator';
import { Field } from 'react-final-form';

type PropTypes = {
  basket: Basket;
  departureDate?: string;
  form: FormApi<BookDepartureFormValues>;
  paymentPlanStartDate: string | null;
};

export const PaymentOptions: FC<PropTypes> = ({
  basket,
  departureDate,
  form,
  paymentPlanStartDate,
}) => {
  const { availablePaymentOptions, tourDeposit } = basket;
  const [optionType, setOptionType] = useState<PaymentOptionType>(
    getDefaultPaymentOptionType(
      availablePaymentOptions,
      basket.selectedPaymentOption?.optionType,
    ),
  );

  const depositAmount = useMemo(() => {
    return form.getFieldState('selectedPaymentOptionDepositAmount')?.value || 0;
  }, [form]);

  const remainingPaymentAfterDeposit = useMemo(() => {
    return basket.tripTotal - depositAmount;
  }, [basket.tripTotal, depositAmount]);

  const handleOptionChange = useCallback(
    (selectedValue: string) => {
      const selectedPaymentOptionType = selectedValue as PaymentOptionType;
      if (selectedPaymentOptionType !== optionType) {
        if (
          selectedPaymentOptionType === PaymentOptionType.FullBalance ||
          selectedPaymentOptionType === PaymentOptionType.BookNowPayLater
        ) {
          form.change('selectedPaymentOptionStartDate', null);
        } else if (
          selectedPaymentOptionType === PaymentOptionType.ThreeMonthsPlan ||
          selectedPaymentOptionType === PaymentOptionType.SixMonthsPlan
        ) {
          if (departureDate) {
            const defaultStartDate = getPaymentPlanLatestStartDate({
              paymentOption: selectedPaymentOptionType,
              departureDate,
            });
            form.change('selectedPaymentOptionStartDate', defaultStartDate);
          }
        }
        setOptionType(selectedPaymentOptionType);
        form.change('selectedPaymentOptionType', selectedPaymentOptionType);
      }
    },
    [optionType, form, departureDate],
  );

  const radioAccordionOptions = availablePaymentOptions
    .map((option) => {
      switch (option.type) {
        case PaymentOptionType.FullBalance: {
          return createPayFullAmountRadioGroupOption({ basket });
        }
        case PaymentOptionType.BookNowPayLater: {
          return createBuyNowPayLaterRadioGroupOption({
            tourDeposit,
            remainingPaymentAfterDeposit,
            departureDate,
            basket,
          });
        }
        case PaymentOptionType.ThreeMonthsPlan:
        case PaymentOptionType.SixMonthsPlan: {
          if (!departureDate) {
            return null;
          }

          return createPaymentPlanOptionContent({
            paymentPlanType: option.type,
            paymentPlanStartDate: paymentPlanStartDate,
            basket,
            departureDate,
            form,
          });
        }
        default: {
          return null;
        }
      }
    })
    .filter((option) => option !== null) as RadioAccordionGroupOption[];

  if (isOnlyFullBalanceOptionAvailable(availablePaymentOptions)) {
    return null;
  }

  return (
    <>
      <Divider sx={{ m: 0 }} />
      <Stack gap={2}>
        <Typography variant="H5">Payment choices</Typography>
        <Typography variant="Body S">Choose how you’d like to book and pay</Typography>
        <Field name="selectedPaymentOptionType">
          {() => (
            <RadioAccordionGroup
              options={radioAccordionOptions}
              defaultValue={optionType}
              onSelectedChange={handleOptionChange}
              testid="payment-options"
            />
          )}
        </Field>
      </Stack>
    </>
  );
};
