import { PayPage } from './pay/PayPage';
import { ProtectedRoute } from '@src/auth/ProtectedRoute';
import { ReservationExpiredPage } from './reservation-expired/ReservationExpiredPage';
import { BookDeparturePage } from '@src/book-departure/BookDeparturePage';
import { CreateAccountPage } from '@src/create-account/CreateAccountPage';
import { LogInPage } from '@src/log-in/LogInPage';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';
import { TestPayPage } from '@src/test-pay/TestPayPage';
import { FC, useLayoutEffect } from 'react';
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useLocation,
} from 'react-router-dom';
import { PaymentSuccessPage } from './pay/PaymentSuccessPage';
import { SoldOutPage } from './shared/sold-out/SoldOutPage';
import { BillingDetailsPage } from './billing-details/BillingDetailsPage';
import { MyTripsPage } from '@src/my-account/MyTrips';
import { MY_ACCOUNT_TAB_HANDLE, MyAccountTab } from '@src/my-account/MyAccountTabs';
import { MyAccountParentPage } from '@src/my-account/MyAccountParentPage';
import { BookedTripPage } from './my-account/BookedTripPage';
import { MyAccountPage } from '@src/my-account/account-details/MyAccountPage';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useCustomerAuthorization } from './auth/AuthorizationProvider';
import { BalancePayment } from '@src/balance-payment/BalancePaymentPage';
import { TooOldPage } from './too-old/TooOldPage';
import { TooYoungPage } from './too-young/TooYoungPage';
import { CombinedCheckoutPage } from './combined-checkout/CombinedCheckoutPage';
import { BookPageV3 } from '@src/v3/BookPageV3';
import { OutOfAgeRangePage } from './out-of-age-range/OutOfAgeRangePage';

const Root: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useCustomerAuthorization();

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(CheckoutRoutePath.MY_ACCOUNT_DETAILS.value);
    }
  }, [currentUser, navigate, location.pathname]);

  return (
    <div>
      <Outlet />
    </div>
  );
};

export const CheckoutAppRouter = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      children: [
        {
          path: CheckoutRoutePath.COMBINED_CHECKOUT.value,
          element: <CombinedCheckoutPage />,
        },
        { path: CheckoutRoutePath.LOGIN.value, element: <LogInPage /> },
        { path: CheckoutRoutePath.SOLD_OUT.value, element: <SoldOutPage /> },
        {
          path: CheckoutRoutePath.OUT_OF_AGE_RANGE.value,
          element: <OutOfAgeRangePage />,
        },
        { path: CheckoutRoutePath.TEST_PAY.value, element: <TestPayPage /> },
        {
          path: CheckoutRoutePath.RESERVATION_EXPIRED.value,
          element: <ReservationExpiredPage />,
        },
        {
          path: CheckoutRoutePath.REGISTER.value,
          element: <CreateAccountPage />,
        },
        {
          path: CheckoutRoutePath.TOO_OLD.value,
          element: <TooOldPage />,
        },
        {
          path: CheckoutRoutePath.TOO_YOUNG.value,
          element: <TooYoungPage />,
        },
        {
          element: <ProtectedRoute navigateTo={CheckoutRoutePath.LOGIN.value} />,
          children: [
            {
              element: <MyAccountParentPage />,
              children: [
                {
                  path: CheckoutRoutePath.MY_TRIPS.value,
                  element: <MyTripsPage />,
                  handle: { [MY_ACCOUNT_TAB_HANDLE]: MyAccountTab.MY_TRIPS },
                },
                {
                  path: CheckoutRoutePath.BOOKING_DETAILS.value,
                  element: <BookedTripPage />,
                  handle: { [MY_ACCOUNT_TAB_HANDLE]: MyAccountTab.MY_TRIPS },
                },
                {
                  path: CheckoutRoutePath.MY_ACCOUNT_DETAILS.value,
                  element: <MyAccountPage />,
                  handle: { [MY_ACCOUNT_TAB_HANDLE]: MyAccountTab.MY_ACCOUNT_DETAILS },
                },
                {
                  path: CheckoutRoutePath.BALANCE_PAYMENT.value,
                  element: <BalancePayment />,
                  handle: { [MY_ACCOUNT_TAB_HANDLE]: MyAccountTab.MY_TRIPS },
                },
              ],
            },
          ],
        },
        {
          path: CheckoutRoutePath.BOOK_V3.value,
          element: <BookPageV3 />,
        },
        {
          element: <ProtectedRoute navigateTo={CheckoutRoutePath.REGISTER.value} />,
          children: [
            {
              path: CheckoutRoutePath.BOOK_DEPARTURE.value,
              element: <BookDeparturePage />,
            },
            {
              path: CheckoutRoutePath.BILLING_DETAILS.value,
              element: <BillingDetailsPage />,
            },
            {
              path: CheckoutRoutePath.PAY.value,
              element: <PayPage />,
            },
            {
              path: CheckoutRoutePath.PAYMENT_SUCCESS.value,
              element: <PaymentSuccessPage />,
            },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};
