import { CheckoutExpandableSection } from '@src/shared/checkout-sections-expandable/CheckoutExpandableSections';
import { CheckoutSectionName } from '@src/v3/checkout-sections/CheckoutSections';
import { Typography } from 'design-system';

export const PayAndFinishSection = ({
  activeSection,
}: {
  activeSection: CheckoutSectionName;
}) => {
  return (
    <CheckoutExpandableSection
      title="Pay and finish"
      open={activeSection === CheckoutSectionName.PayAndFinishSection}
      topDivider
      bottomDivider
    >
      <Typography variant="Body S">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Nulla
        facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla
        facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla
        facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla
        facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla
        facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla
        facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla
        facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla
        facilisi. Nulla
      </Typography>
    </CheckoutExpandableSection>
  );
};
