import { useRouting } from '@src/shared/hooks';
import { CreateAccountSection } from '@src/v3/checkout-sections/CreateAccountSection';
import { PayAndFinishSection } from '@src/v3/checkout-sections/PayAndFinishSection';
import { PaymentOptionsSection } from '@src/v3/checkout-sections/PaymentOptionsSection';
import { PersonaliseBookingSection } from '@src/v3/checkout-sections/PersonaliseBookingSection';
import { Stack } from 'design-system';
import { useState } from 'react';

export const CheckoutSections = () => {
  const { queryParams } = useRouting<{
    section: CheckoutSectionName;
  }>();
  const { updateQueryParams } = useRouting<{ section: CheckoutSectionName }>();
  const [personaliseBookingCompleted, setPersonaliseBookingCompleted] = useState(false);
  const [paymentOptionsCompleted, setPaymentOptionsCompleted] = useState(false);

  const onCompleteSection = (completedSection: CheckoutSectionName) => {
    if (completedSection === CheckoutSectionName.PersonaliseBookingSection) {
      setPersonaliseBookingCompleted(true);
      if (!paymentOptionsCompleted) {
        updateQueryParams({
          toAdd: { section: CheckoutSectionName.PaymentOptionsSection },
          preserveExisting: true,
        });
      } else {
        updateQueryParams({
          toAdd: { section: CheckoutSectionName.PayAndFinishSection },
          preserveExisting: true,
        });
      }
    } else if (completedSection === CheckoutSectionName.PaymentOptionsSection) {
      setPaymentOptionsCompleted(true);
      updateQueryParams({
        toAdd: { section: CheckoutSectionName.PayAndFinishSection },
        preserveExisting: true,
      });
    }
  };

  return (
    <Stack direction="column" gap={4}>
      <CreateAccountSection activeSection={queryParams.section} />
      <PersonaliseBookingSection
        completed={personaliseBookingCompleted}
        onComplete={onCompleteSection}
        activeSection={queryParams.section}
      />
      <PaymentOptionsSection
        completed={paymentOptionsCompleted}
        onComplete={onCompleteSection}
        activeSection={queryParams.section}
      />
      <PayAndFinishSection activeSection={queryParams.section} />
    </Stack>
  );
};

export enum CheckoutSectionName {
  CreateAccountSection = 'create-account',
  PersonaliseBookingSection = 'personalise-booking',
  PaymentOptionsSection = 'payment-options',
  PayAndFinishSection = 'pay-and-finish',
}
