import { Field } from 'react-final-form';
import { FieldValidator } from 'final-form';
import { DateInput, DateInputProps } from './DateInput';
import { FC } from 'react';

interface FormDateInputProps extends Omit<DateInputProps, 'onChange'> {
  name: string;
  parse?: (value: string) => string;
  format?: (value: string) => string;
  validate?: FieldValidator<string>;
  validateFields?: string[];
}

export const FormDateInput: FC<FormDateInputProps> = (props: FormDateInputProps) => {
  return (
    <Field<string>
      name={props.name}
      parse={props.parse}
      format={props.format}
      validate={props.validate}
      validateFields={props.validateFields}
    >
      {({ input, meta }) => (
        <DateInput
          {...input}
          {...props}
          name={props.name}
          error={meta.touched && !!meta.error}
          helperText={props.helperText || (meta.touched ? (meta.error as string) : '')}
        />
      )}
    </Field>
  );
};
