import { generatePath } from 'react-router-dom';

export const CheckoutRoutePath = {
  LOGIN: {
    value: '/login',
    relative: 'login',
  },
  REGISTER: {
    value: '/register',
    relative: 'register',
  },
  MY_TRIPS: {
    value: '/account/trips',
  },
  BOOKING_DETAILS: {
    value: '/account/trips/:tripId',
    generatePath: (tripId: string) =>
      generatePath(CheckoutRoutePath.BOOKING_DETAILS.value, { tripId }),
  },
  BALANCE_PAYMENT: {
    value: '/account/trips/:tripId/payment',
    generatePath: (tripId: string) =>
      generatePath(CheckoutRoutePath.BALANCE_PAYMENT.value, { tripId }),
  },
  MY_ACCOUNT_DETAILS: {
    value: '/account/details',
  },
  BOOK_DEPARTURE: {
    value: '/book',
  },
  BOOK_V3: {
    value: '/book-v3',
  },
  BILLING_DETAILS: {
    value: '/billing-details',
  },
  BOOKINGS: {
    value: '/bookings',
  },
  SOLD_OUT: {
    value: '/sold-out',
  },
  PAY: {
    value: '/pay',
    relative: 'pay',
  },
  PAYMENT_SUCCESS: {
    value: '/payment-success',
  },
  TEST_PAY: {
    // A route that is hard to guess meant to be used by us for testing purposes
    // Nothing sensitive is rendered here, its just the payment widget with some inputs but it's better to keep it hidden
    value: '/01d616e8-c8a9-4706-8f23-88bcd261dc95',
  },
  RESERVATION_EXPIRED: {
    value: '/reservation-expired',
  },
  TOO_OLD: {
    value: '/not-eligible-above',
  },
  TOO_YOUNG: {
    value: '/not-eligible-below',
  },
  OUT_OF_AGE_RANGE: {
    value: '/out-of-age-range',
  },
  COMBINED_CHECKOUT: {
    value: '/checkout',
  },
};
