import { useRouting } from '@src/shared/hooks';
import { CheckoutExpandableSection } from '@src/shared/checkout-sections-expandable/CheckoutExpandableSections';
import { CheckoutSectionName } from '@src/v3/checkout-sections/CheckoutSections';
import { Button } from 'design-system';

export const PaymentOptionsSection = ({
  completed,
  onComplete,
  activeSection,
}: {
  activeSection: CheckoutSectionName;
  completed: boolean;
  onComplete: (sectionName: CheckoutSectionName) => void;
}) => {
  const { updateQueryParams } = useRouting<{ section: CheckoutSectionName }>();

  return (
    <CheckoutExpandableSection
      title="Payment options"
      open={activeSection === CheckoutSectionName.PaymentOptionsSection}
      completed={completed}
      onEdit={() => {
        updateQueryParams(
          {
            preserveExisting: true,
            toAdd: { section: CheckoutSectionName.PaymentOptionsSection },
          },
          { replace: true },
        );
      }}
    >
      <Button
        variant="contained"
        onClick={() => {
          onComplete(CheckoutSectionName.PaymentOptionsSection);
        }}
      >
        Continue
      </Button>
    </CheckoutExpandableSection>
  );
};
