import { Box } from '@mui/system';
import { FlashPackWordLogoIcon, Link, useTheme } from 'design-system';
import React from 'react';

const Header: React.FC = () => {
  const theme = useTheme();
  return (
    <Box
      style={{
        height: 74,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.principal.grey50}`,
      }}
    >
      <Link href={'https://www.flashpack.com/'} sx={{ display: 'inline-flex' }}>
        <FlashPackWordLogoIcon sx={{ height: 50, width: 72 }} />
      </Link>
    </Box>
  );
};

export default Header;
