import { differenceInDays } from 'date-fns';
import { useMutation } from '@apollo/client';
import {
  AirwallexDropInElement,
  CreatedPaymentIntent,
} from '../airwallex/AirwallexDropIn';
import * as Sentry from '@sentry/react';
import { CreatePaymentIntentDocument, CustomerCurrency } from '@flashpack/graphql';
import { GenericError, Stack, useTheme, useMediaQuery } from 'design-system';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Layout from '@src/shared/Layout/Layout';
import BookDepartureHeader from '@src/shared/book-departure-header/BookDepartureHeader';
import { TotalToPay } from './TotalToPay';
import ReservationTimer from '@src/book-departure/reservation-timer/ReservationTimer';
import { useBasket, useExpiredReservationRedirect, useRouting } from '@src/shared/hooks';
import { OrderDetails } from '@src/shared/order-details/OrderDetails';
import { useOrderDetails } from '@src/shared/hooks/useOrderDetails';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';
import { PageFormTitle } from '@src/shared/page-form-title/PageFormTitle';
import { ResponsiveSideBySide } from '@src/shared/responsive-side-by-side/ResponsiveSideBySide';
import { PageFormLoader } from '@src/shared/page-form-loader/PageFormLoader';
import ToPayWidget from './ToPayWidget';

const FULL_BALANCE_CUTOFF_DAYS = 97;

// Deprecated
export const PayPage = () => {
  const [createPaymentIntent] = useMutation(CreatePaymentIntentDocument);
  const [amountToPay, setAmountToPay] = useState<number | null>(null);

  const { queryParams, navigate } = useRouting<{
    departureCode: string;
    currencyCode: string;
  }>();
  const { currencyCode } = queryParams;

  const { basket, loading, error } = useBasket();
  const { data: orderDetailsData, loading: orderDetailsLoading } = useOrderDetails();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const customerCurrency = currencyCode.toLowerCase() as CustomerCurrency;

  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [paymentError, setPaymentError] = useState(false);

  const toPayWarningMessage = useMemo(() => {
    const startDate = orderDetailsData?.orderDetails.startDate;
    if (!startDate) {
      return '';
    }
    const daysUntilStartDate = differenceInDays(new Date(startDate), new Date());
    if (daysUntilStartDate < FULL_BALANCE_CUTOFF_DAYS) {
      return 'As this adventure begins soon, full payment is required now.';
    }
    return '';
  }, [orderDetailsData?.orderDetails.startDate]);

  const handleAirwallexSuccess = useCallback(() => {
    setPaymentSuccessful(true);
  }, []);

  const handleAirwallexError = useCallback((error: unknown) => {
    Sentry.captureMessage('Airwallex payment failed', {
      level: 'fatal',
      extra: { error: JSON.stringify(error) },
    });
    setPaymentError(true);
  }, []);

  useExpiredReservationRedirect({
    skip: loading || !basket?.reservation,
    reservationExpiryDate: basket?.reservation?.expiresAt,
  });

  useEffect(() => {
    if (paymentSuccessful) {
      navigate(CheckoutRoutePath.PAYMENT_SUCCESS.value, { preserveExisting: true });
    }
  }, [paymentSuccessful, navigate]);

  if (loading) {
    return (
      <Layout
        HeroComponent={
          <BookDepartureHeader location={orderDetailsData?.orderDetails.location} />
        }
      >
        <PageFormLoader />
      </Layout>
    );
  }

  if (error || !basket) {
    return (
      <GenericError
        error={'Something went wrong while creating your order. Please try again later.'}
      />
    );
  }

  const createIntent = async (): Promise<CreatedPaymentIntent> => {
    const { data: paymentIntent } = await createPaymentIntent({
      variables: {
        input: {
          amount: basket.tripTotal, // todo: remove it; take the value in the server
          currency: customerCurrency,
          basketId: basket.id,
        },
      },
    });
    if (paymentIntent) {
      setAmountToPay(paymentIntent.createPaymentIntent.amount);
      return paymentIntent.createPaymentIntent as CreatedPaymentIntent;
    }
    throw new Error('Failed to create intent!');
  };

  return (
    <Layout
      HeroComponent={
        <BookDepartureHeader location={orderDetailsData?.orderDetails.location} />
      }
    >
      <ResponsiveSideBySide
        mainSection={
          <Stack gap={3}>
            <PageFormTitle>Payment</PageFormTitle>
            <Stack gap={1}>
              {basket.reservation && (
                <ReservationTimer endTime={new Date(basket.reservation.expiresAt)} />
              )}
            </Stack>

            <TotalToPay
              currency={customerCurrency}
              finalCost={amountToPay}
              selectedPaymentOption={basket.selectedPaymentOption}
              warningMessage={toPayWarningMessage}
              basket={basket}
            />
            <AirwallexDropInElement
              onSuccess={handleAirwallexSuccess}
              onError={handleAirwallexError}
              createIntent={createIntent}
              dropInType="payment"
            />
            {!!paymentError && (
              <GenericError
                error={
                  'Something went wrong when processing your payment. Please try again later.'
                }
              />
            )}
          </Stack>
        }
        additionalSection={
          isSmallScreen ? null : (
            <Stack gap={3}>
              <OrderDetails
                loading={orderDetailsLoading}
                orderDetails={orderDetailsData?.orderDetails}
              />

              <ToPayWidget currency={customerCurrency} basket={basket} />
            </Stack>
          )
        }
      />
    </Layout>
  );
};
