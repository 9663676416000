import { ReactComponent as ActivitySvg } from './ActivityIcon.svg';
import { ReactComponent as ActivityMobileSvg } from './ActivityMobileIcon.svg';
import { ReactComponent as ArchiveSvg } from './Archive.svg';
import { ReactComponent as UnarchiveSvg } from './Unarchive.svg';
import { ReactComponent as AttachSvg } from './AttachIcon.svg';
import { ReactComponent as BoatSvg } from './transfer/Boat.svg';
import { ReactComponent as CheckSvg } from './CheckIcon.svg';
import { ReactComponent as CheckSvg2 } from './CheckIcon2.svg';
import { ReactComponent as CircleArrowDownSvg } from './CircleArrowDown.svg';
import { ReactComponent as CircleArrowRightSvg } from './CircleArrowRight.svg';
import { ReactComponent as ChecklistSvg } from './Checklist.svg';
import { ReactComponent as TickSvg } from './Tick.svg';

import { ReactComponent as CoachSvg } from './transfer/Coach.svg';
import { ReactComponent as DepartureAcceptedSvg } from './departure/Accepted.svg';
import { ReactComponent as DepartureCompletedSvg } from './departure/Completed.svg';
import { ReactComponent as DepartureDepartedSvg } from './departure/Departed.svg';
import { ReactComponent as DepartureSecuredSvg } from './departure/Secured.svg';
import { ReactComponent as EmptyAvatarSvg } from './EmptyAvatar.svg';
import { ReactComponent as ExclamationSvg } from './Exclamation.svg';
import { ReactComponent as FailureSvg } from './Failure.svg';
import { ReactComponent as FlightSvg } from './transfer/Flight.svg';
import { ReactComponent as FlightSlantedSvg } from './transfer/FlightSlanted.svg';
import { ReactComponent as LockedSvg } from './LockedIcon.svg';
import { ReactComponent as LogoSvg } from './Logo.svg';
import { ReactComponent as MinibusSvg } from './transfer/Minibus.svg';
import { ReactComponent as Vehicle4WDSvg } from './transfer/Vehicle4WD.svg';
import { ReactComponent as OpenInNewTabSvg } from './OpenInNewTab.svg';
import { ReactComponent as OpenDropdownSvg } from './OpenDropdown.svg';
import { ReactComponent as SuccessSvg } from './Success.svg';
import { ReactComponent as TaxiSvg } from './transfer/Taxi.svg';
import { ReactComponent as TrainSvg } from './transfer/Train.svg';
import { ReactComponent as TransferSvg } from './TransferIcon.svg';
import { ReactComponent as WarningSvg } from './Warning.svg';
import { ReactComponent as CalendarSvg } from './Calendar.svg';
import { ReactComponent as ClockSvg } from './Clock.svg';
import { ReactComponent as DollarSymbolSvg } from './Dollar Symbol.svg';
import { ReactComponent as CommentSymbolSvg } from './Comment.svg';
import { ReactComponent as CommentsSymbolSvg } from './Comments.svg';
import { ReactComponent as TimePassedSymbolSvg } from './TimePassed.svg';
import { ReactComponent as InformationIconSvg } from './InformationIcon.svg';
import { ReactComponent as InfoIconSvg } from './InfoIcon.svg';
import { ReactComponent as FlagIconSvg } from './FlagIcon.svg';
import { ReactComponent as WrenchIconSvg } from './Wrench.svg';
import { ReactComponent as CopyPasteClipboard } from './CopyPasteClipboard.svg';
import { ReactComponent as PeopleGroupSvg } from './PeopleGroup.svg';
import { ReactComponent as ArrivalFlight } from './ArrivalFlight.svg';
import { ReactComponent as DepartureFlight } from './DepartureFlight.svg';

export { ReactComponent as EmptyTimelineIcon } from './timeline/EmptyTimelineIcon.svg';
import { ReactComponent as AccommodationSvg } from './timeline/Accommodation.svg';
import { ReactComponent as AccommodationMobileSvg } from './AccommodationMobile.svg';
import { ReactComponent as DeleteTrashCanSvg } from './DeleteTrashCan.svg';
import { ReactComponent as FinanceSvg } from './Finance.svg';
import { ReactComponent as AttentionRequiredSvg } from './AttentionRequired.svg';
import { ReactComponent as PadlockSvg } from './PadlockIcon.svg';

import { ReactComponent as UnavailableSvg } from './Unavailable.svg';
import { ReactComponent as ThumbsUpSvg } from './ThumbsUp.svg';
import { ReactComponent as SecureSvg } from './Secure.svg';
import { ReactComponent as DangerRedSvg } from './DangerRed.svg';
import { ReactComponent as AirplaneSvg } from './Airplane.svg';
import { ReactComponent as ChatSvg } from './Chat.svg';
import { ReactComponent as ArrowLeft } from './ArrowLeft.svg';
import { ReactComponent as ArrowRight } from './ArrowRight.svg';
import { ReactComponent as ArrowDown } from './ArrowDown.svg';
import { ReactComponent as LocationSvg } from './Location.svg';
import { ReactComponent as SuitcaseSvg } from './Suitcase.svg';
import { ReactComponent as CircleAddSvg } from './CircleAdd.svg';
import { ReactComponent as CircleCloseSvg } from './CircleClose.svg';
import { ReactComponent as CircleCloseModalSvg } from './CircleCloseModal.svg';
import { ReactComponent as PhoneWithinCircleSvg } from './PhoneWithinCircle.svg';
import { ReactComponent as PhoneOutlinedSvg } from './PhoneOutlined.svg';
import { ReactComponent as RadioCheckedSvg } from './RadioChecked.svg';
import { ReactComponent as RadioUncheckedSvg } from './RadioUnchecked.svg';
import { ReactComponent as CheckboxCheckedSvg } from './CheckboxChecked.svg';
import { ReactComponent as CheckboxUncheckedSvg } from './CheckboxUnchecked.svg';
import { ReactComponent as CameraSvg } from './Camera.svg';
import { ReactComponent as FlashPackBadgeSvg } from './FlashPackBadge.svg';
import { ReactComponent as FlashPackWordLogoSvg } from './FlashPackWordLogo.svg';
import { ReactComponent as SubtractSvg } from './Subtract.svg';

import { ReactComponent as SharedAccommodationInclusionSvg } from './Hotel.svg';
import { ReactComponent as EquipmentInclusionSvg } from './Kayak.svg';
import { ReactComponent as GuideInclusionSvg } from './Map.svg';
import { ReactComponent as LocalTransportInclusionSvg } from './Boat.svg';
import { ReactComponent as MostMealsIncludedInclusionSvg } from './Cocktail.svg';
import { ReactComponent as MaxTravelersPerGroupInclusionSvg } from './Backpack.svg';
import { ReactComponent as ExclusivelyForAgeRangesInclusionSvg } from './AgeRange.svg';
import { ReactComponent as PrivateAirportTransferInclusionSvg } from './Transfer.svg';
import { ReactComponent as CarbonFootprintInclusionSvg } from './CarbonFootprint.svg';
import { ReactComponent as LocalGuideTipsSvgInclusionSvg } from './LocalGuideTips.svg';
import { ReactComponent as PhoneWithNotificationsSvg } from './phone-with-notifications.svg';

import { ReactComponent as SocialMediaFacebookSvg } from './social-media/Facebook.svg';
import { ReactComponent as SocialMediaInstagramSvg } from './social-media/Instagram.svg';
import { ReactComponent as SocialMediaLinkedInSvg } from './social-media/LinkedIn.svg';
import { ReactComponent as SocialMediaPinterestSvg } from './social-media/Pinterest.svg';
import { ReactComponent as SocialMediaTikTokSvg } from './social-media/TikTok.svg';
import { ReactComponent as SocialMediaXSvg } from './social-media/X.svg';

import { ReactComponent as PaymentOptionAmexSvg } from './payment-options/amex.svg';
import { ReactComponent as PaymentOptionMastercardSvg } from './payment-options/mastercard.svg';
import { ReactComponent as PaymentOptionVisaSvg } from './payment-options/visa.svg';

import { ReactComponent as ProtectionLicenseAbtaSvg } from './protection-licenses/abta.svg';
import { ReactComponent as ProtectionLicenseAtolSvg } from './protection-licenses/atol.svg';
import { ReactComponent as EditSvg } from './Edit.svg';
import { ReactComponent as CompleteSvg } from './Complete.svg';
import { SvgIcon, SvgIconProps } from '@mui/material';

function wrapSvgIcon(
  svgComponent: React.FunctionComponent,
  name: string,
): typeof SvgIcon {
  const SvgIconComponent = (props: SvgIconProps) => (
    <SvgIcon
      sx={{ width: 24, height: 24 }}
      inheritViewBox={true}
      component={svgComponent}
      {...props}
    />
  );
  SvgIconComponent.muiName = name;
  SvgIconComponent.displayName = name;
  return SvgIconComponent;
}

export const ActivityIcon = wrapSvgIcon(ActivitySvg, 'ActivityIcon');
export const ActivityMobileIcon = wrapSvgIcon(ActivityMobileSvg, 'ActivityIcon');
export const ArchiveIcon = wrapSvgIcon(ArchiveSvg, 'ArchiveIcon');
export const UnarchiveIcon = wrapSvgIcon(UnarchiveSvg, 'UnarchiveIcon');
export const AttachIcon = wrapSvgIcon(AttachSvg, 'AttachIcon');
export const BoatTransferIcon = wrapSvgIcon(BoatSvg, 'BoatTransferIcon');
export const CheckIcon = wrapSvgIcon(CheckSvg, 'CheckIcon');
export const CheckIcon2 = wrapSvgIcon(CheckSvg2, 'CheckIcon2');
export const CircleArrowDown = wrapSvgIcon(CircleArrowDownSvg, 'CircleArrowDownIcon');
export const CircleArrowRight = wrapSvgIcon(CircleArrowRightSvg, 'CircleArrowRightIcon');
export const Checklist = wrapSvgIcon(ChecklistSvg, 'ChecklistIcon');
export const CoachTransferIcon = wrapSvgIcon(CoachSvg, 'CoachTransferIcon');
export const EmptyAvatarIcon = wrapSvgIcon(EmptyAvatarSvg, 'EmptyAvatarIcon');
export const DepartureAcceptedIcon = wrapSvgIcon(
  DepartureAcceptedSvg,
  'DepartureAcceptedIcon',
);
export const DepartureCompletedIcon = wrapSvgIcon(
  DepartureCompletedSvg,
  'DepartureCompletedIcon',
);
export const DepartureDepartedIcon = wrapSvgIcon(
  DepartureDepartedSvg,
  'DepartureDepartedIcon',
);
export const DepartureSecuredIcon = wrapSvgIcon(
  DepartureSecuredSvg,
  'DepartureSecuredIcon',
);
export const ExclamationIcon = wrapSvgIcon(ExclamationSvg, 'ExclamationIcon');
export const FailureIcon = wrapSvgIcon(FailureSvg, 'FailureIcon');
export const FlightTransferIcon = wrapSvgIcon(FlightSvg, 'FlightTransferIcon');
export const FlightSlantedIcon = wrapSvgIcon(FlightSlantedSvg, 'FlightSlantedIcon');
export const LockedIcon = wrapSvgIcon(LockedSvg, 'LockedIcon');
export const LogoIcon = wrapSvgIcon(LogoSvg, 'LogoIcon');
export const MinibusTransferIcon = wrapSvgIcon(MinibusSvg, 'MinibusTransferIcon');
export const Vehicle4WDTransferIcon = wrapSvgIcon(
  Vehicle4WDSvg,
  'Vehicle4WDTransferIcon',
);
export const OpenInNewTab = wrapSvgIcon(OpenInNewTabSvg, 'OpenInNewTab');
export const OpenDropdown = wrapSvgIcon(OpenDropdownSvg, 'OpenDropdown');
export const SuccessIcon = wrapSvgIcon(SuccessSvg, 'SuccessIcon');
export const TaxiTransferIcon = wrapSvgIcon(TaxiSvg, 'TaxiTransferIcon');
export const TrainTransferIcon = wrapSvgIcon(TrainSvg, 'TrainTransferIcon');
export const TransferIcon = wrapSvgIcon(TransferSvg, 'TransferIcon');
export const WarningIcon = wrapSvgIcon(WarningSvg, 'WarningIcon');
export const CalendarIcon = wrapSvgIcon(CalendarSvg, 'CalendarIcon');
export const ClockIcon = wrapSvgIcon(ClockSvg, 'ClockIcon');
export const DollarSymbolIcon = wrapSvgIcon(DollarSymbolSvg, 'DollarSymbolIcon');
export const CommentIcon = wrapSvgIcon(CommentSymbolSvg, 'Comment');
export const CommentsIcon = wrapSvgIcon(CommentsSymbolSvg, 'Comments');
export const TimePassedIcon = wrapSvgIcon(TimePassedSymbolSvg, 'TimePassed');
export const AccommodationIcon = wrapSvgIcon(AccommodationSvg, 'AccommodationIcon');
export const AccommodationMobileIcon = wrapSvgIcon(
  AccommodationMobileSvg,
  'AccommodationMobileIcon',
);
export const DeleteTrashCanIcon = wrapSvgIcon(DeleteTrashCanSvg, 'DeleteTrashCanIcon');
export const FinanceIcon = wrapSvgIcon(FinanceSvg, 'FinanceIcon');
export const AttentionRequiredIcon = wrapSvgIcon(AttentionRequiredSvg, 'ErrorIcon');
export const InformationIcon = wrapSvgIcon(InformationIconSvg, 'InformationIcon');
export const InfoIcon = wrapSvgIcon(InfoIconSvg, 'InfoIcon');
export const FlagIcon = wrapSvgIcon(FlagIconSvg, 'FlagIcon');
export const WrenchIcon = wrapSvgIcon(WrenchIconSvg, 'WrenchIcon');
export const CopyPasteClipboardIcon = wrapSvgIcon(
  CopyPasteClipboard,
  'CopyPasteClipboardIcon',
);
export const PeopleGroupIcon = wrapSvgIcon(PeopleGroupSvg, 'PeopleGroupIcon');
export const PadlockIcon = wrapSvgIcon(PadlockSvg, 'PadlockIcon');
export const UnavailableIcon = wrapSvgIcon(UnavailableSvg, 'UnavailableIcon');
export const ThumbsUpIcon = wrapSvgIcon(ThumbsUpSvg, 'ThumbsUpIcon');
export const SecureIcon = wrapSvgIcon(SecureSvg, 'SecureIcon');
export const DangerRedIcon = wrapSvgIcon(DangerRedSvg, 'DangerRedIcon');
export const AirplaneIcon = wrapSvgIcon(AirplaneSvg, 'AirplaneIcon');
export const ChatIcon = wrapSvgIcon(ChatSvg, 'ChatIcon');
export const ArrowLeftIcon = wrapSvgIcon(ArrowLeft, 'ArrowLeftIcon');
export const ArrowRightIcon = wrapSvgIcon(ArrowRight, 'ArrowRightIcon');
export const ArrowDownIcon = wrapSvgIcon(ArrowDown, 'ArrowDownIcon');
export const LocationIcon = wrapSvgIcon(LocationSvg, 'LocationIcon');
export const SuitcaseIcon = wrapSvgIcon(SuitcaseSvg, 'SuitcaseIcon');
export const CircleAddIcon = wrapSvgIcon(CircleAddSvg, 'CircleAddIcon');
export const CircleCloseIcon = wrapSvgIcon(CircleCloseSvg, 'CircleCloseIcon');
export const CircleCloseModalIcon = wrapSvgIcon(
  CircleCloseModalSvg,
  'CircleCloseModalIcon',
);
export const PhoneWithinCircleIcon = wrapSvgIcon(
  PhoneWithinCircleSvg,
  'PhoneWithinCircleIcon',
);
export const ArrivalFlightIcon = wrapSvgIcon(ArrivalFlight, 'ArrivalFlightIcon');
export const DepartureFlightIcon = wrapSvgIcon(DepartureFlight, 'DepartureFlightIcon');
export const PhoneOutlinedIcon = wrapSvgIcon(PhoneOutlinedSvg, 'PhoneOutlinedIcon');
export const RadioCheckedIcon = wrapSvgIcon(RadioCheckedSvg, 'RadioCheckedIcon');
export const RadioUncheckedIcon = wrapSvgIcon(RadioUncheckedSvg, 'RadioUncheckedIcon');
export const CheckboxCheckedIcon = wrapSvgIcon(CheckboxCheckedSvg, 'CheckboxCheckedIcon');
export const CheckboxUncheckedIcon = wrapSvgIcon(
  CheckboxUncheckedSvg,
  'CheckboxUncheckedIcon',
);
export const CameraIcon = wrapSvgIcon(CameraSvg, 'CameraIcon');
export const FlashPackBadgeIcon = wrapSvgIcon(FlashPackBadgeSvg, 'FlashPackBadge');
export const FlashPackWordLogoIcon = wrapSvgIcon(
  FlashPackWordLogoSvg,
  'FlashPackWordLogo',
);
export const SubtractIcon = wrapSvgIcon(SubtractSvg, 'SubtractIcon');

export const SharedAccommodationInclusionIcon = wrapSvgIcon(
  SharedAccommodationInclusionSvg,
  'SharedAccommodationInclusionIcon',
);
export const EquipmentInclusionIcon = wrapSvgIcon(
  EquipmentInclusionSvg,
  'EquipmentInclusionIcon',
);

export const GuideInclusionIcon = wrapSvgIcon(GuideInclusionSvg, 'GuideInclusionIcon');

export const LocalTransportInclusionIcon = wrapSvgIcon(
  LocalTransportInclusionSvg,
  'LocalTransportInclusionIcon',
);

export const MostMealsIncludedInclusionIcon = wrapSvgIcon(
  MostMealsIncludedInclusionSvg,
  'MostMealsIncludedInclusionIcon',
);

export const MaxTravelersPerGroupInclusionIcon = wrapSvgIcon(
  MaxTravelersPerGroupInclusionSvg,
  'MaxTravelersPerGroupInclusionIcon',
);
export const ExclusivelyForAgeRangesInclusionIcon = wrapSvgIcon(
  ExclusivelyForAgeRangesInclusionSvg,
  'ExclusivelyForAgeRangesInclusionIcon',
);

export const PrivateAirportTransferInclusionIcon = wrapSvgIcon(
  PrivateAirportTransferInclusionSvg,
  'PrivateAirportTransferInclusionIcon',
);

export const CarbonFootprintInclusionIcon = wrapSvgIcon(
  CarbonFootprintInclusionSvg,
  'CarbonFootprintInclusionIcon',
);

export const LocalGuideTipsSvgInclusionIcon = wrapSvgIcon(
  LocalGuideTipsSvgInclusionSvg,
  'LocalGuideTipsSvgInclusionIcon',
);

export const PhoneWithNotificationsIcon = wrapSvgIcon(
  PhoneWithNotificationsSvg,
  'PhoneWithNotificationsIcon',
);

export const SocialMediaFacebookIcon = wrapSvgIcon(
  SocialMediaFacebookSvg,
  'SocialMediaFacebookIcon',
);
export const SocialMediaInstagramIcon = wrapSvgIcon(
  SocialMediaInstagramSvg,
  'SocialMediaInstagramIcon',
);
export const SocialMediaLinkedInIcon = wrapSvgIcon(
  SocialMediaLinkedInSvg,
  'SocialMediaLinkedInIcon',
);
export const SocialMediaPinterestIcon = wrapSvgIcon(
  SocialMediaPinterestSvg,
  'SocialMediaPinterestIcon',
);
export const SocialMediaTikTokIcon = wrapSvgIcon(
  SocialMediaTikTokSvg,
  'SocialMediaTikTokIcon',
);
export const SocialMediaXIcon = wrapSvgIcon(SocialMediaXSvg, 'SocialMediaXIcon');

export const PaymentOptionAmexIcon = wrapSvgIcon(
  PaymentOptionAmexSvg,
  'PaymentOptionAmexIcon',
);
export const PaymentOptionMastercardIcon = wrapSvgIcon(
  PaymentOptionMastercardSvg,
  'PaymentOptionMastercardIcon',
);
export const PaymentOptionVisaIcon = wrapSvgIcon(
  PaymentOptionVisaSvg,
  'PaymentOptionVisaIcon',
);

export const ProtectionLicenseAbtaIcon = wrapSvgIcon(
  ProtectionLicenseAbtaSvg,
  'ProtectionLicenseAbtaIcon',
);
export const ProtectionLicenseAtolIcon = wrapSvgIcon(
  ProtectionLicenseAtolSvg,
  'ProtectionLicenseAtolIcon',
);

export const TickIcon = wrapSvgIcon(TickSvg, 'TickIcon');

export const EditIcon = wrapSvgIcon(EditSvg, 'EditIcon');
export const CompleteIcon = wrapSvgIcon(CompleteSvg, 'CompleteIcon');
